import React from "react";
import './App.css';
import { Fade } from "react-awesome-reveal";

function ImagenConTitulo(props) {


  return (
    <div className="imagen-con-titulo-custom">
      <a href={props.linkTo} target="_blank">
        <img src={props.imagenUrl} alt={props.titulo} />
      </a>
    </div>
  );
}

function Partner() {
  const customItems = [

    {
      imagenUrl:  "img/partner/nzc.png",
      linkTo: "https://store.nzc.nz/"
    },
    {
      imagenUrl:  "img/partner/nzf.png",
      linkTo: "https://shop.nzfootball.co.nz/"
    },
    {
      imagenUrl:  "img/partner/nzn.png",
      linkTo: "https://shop.netballnz.co.nz/"
    },
    {
      imagenUrl:  "img/partner/nzrl.png",
      linkTo: "https://nzrlshop.co.nz/"
    }
  ];

  return (
    <section className="partner" id="partner">
      <Fade triggerOnce cascade delay={300}>
        <h1 className="titlePartner">SEKEM PARTNERS</h1>
        <h4 className='custom-description'>Explore our partner stores and discover a wide range of exciting products </h4>
      </Fade>



        <div className="container-custom-images">
      <div className="custom-images-container">
        {customItems.map((item, index) => (
          <ImagenConTitulo
            key={index}
            imagenUrl={item.imagenUrl}
            pdfFileName={item.pdfFileName}
            titulo={item.titulo}
            linkTo={item.linkTo}
          />
        ))}
      </div>
      </div>
    </section>
  );
}

export default Partner;
